:root {
    --wkit-accent-hs: 210, 100%;
    --wkit-on-accent-rgb: 255, 255, 255;
    --wkit-bg-rgb: 239, 241, 245;
    --wkit-on-bg-rgb: 10, 14, 34;
    --wkit-font-family: 'Inter', sans-serif;
    --wkit-font-family-mono: 'IBM Plex Mono', monospace;
    /* --wkit-font-size-large: 12px; */
    --wkit-font-size-medium: 14px;
    /* --wkit-font-size-small: 18px; */
    --wkit-line-height-large: 12px;
    --wkit-line-height-medium: 12px;
    --wkit-line-height-small: 12px;
    --wkit-button-width: 284px;
    --wkit-button-height: 28px;
    --wkit-border-radius: 10px;
    --wkit-padding: 10px;
}

.wkit-button {
    height: 16px;
    background-color: transparent;
    /* background-color: hsl(var(--wkit-accent-hs), 0%); */
}

.wkit-button:hover {
    background-color: transparent;
    color: #daddde;
    cursor: pointer;
}

.wkit-connected-button {
    padding: 8px;
    background-color: transparent;
}
.wkit-disconnect-button__container {
    bottom: -44px;
    /* background-color: #0d7187; */
}
